<template>
  <div>
    <h4
      :class="{
        'mt-8 mt-md-16 mb-6 pt-md-4 title': true,
        'text-center': smAndDown,
        'text-left': !smAndDown,
      }">
      {{ title?.split('Container')[0] }}
      <span class="text-primary">Container</span>
      {{ title?.split('Container')[1] }}
    </h4>
    <div class="px-3 px-md-0" v-html="sanitizeHtml(text)" />

    <v-row class="ml-0 pl-md-0" justify="center" justify-md="start" dense no-gutters>
      <v-col cols="12" class="pl-0 pr-3 pr-md-2 d-inline-flex">
        <v-img
          :src="IconTriangle"
          width="50"
          height="50"
          :alt="t('arrow')"
          class="front-icon mr-n7 ml-n3 ml-md-n7 mt-5" />
        <v-menu
          id="menu-zip-find"
          v-model="zipDropdown"
          role="combobox"
          aria-controls="zip-input-detail"
          aria-expanded="false"
          transition="slide-y-transition">
          <template #activator="{ props }">
            <v-text-field
              id="zip-input-detail"
              v-model="postalCodeString"
              width="100%"
              variant="outlined"
              :placeholder="t('your_zip')"
              class="mb-3 mt-4 hero-input"
              v-bind="props"
              :error="errorMarker"
              @focus="resetPayloadZipcode"
              @input="checkZipInput"
              @keydown.enter.prevent="setZipcode" />
          </template>
          <v-list v-if="cities.length > 0">
            <v-list-item v-for="item in cities" :key="item?.zip" @click="selectZipCode(item)">
              <v-list-item-title>
                {{ `${item?.zip}, ${item?.city}` }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="mt-4">
        <v-tooltip top :disabled="!wrongZipcode">
          <template #activator="{ props }">
            <div v-bind="props">
              <ButtonPrimary width="100%" min-height="80px" @click="setZipcode">
                {{ t('show_containers') }}
              </ButtonPrimary>
            </div>
          </template>
          <div class="pa-3">{{ t('enter_valid_zip') }}</div>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import IconTriangle from '@/assets/icons/triangle.svg'
import { useDisplay } from 'vuetify'
import type { City, CityApiResponse } from '~/types/types'
import { sanitizeHtml } from '~/util/sanitizer'
const { t } = useI18n()
const { smAndDown } = useDisplay()
const fractionStore = useFractionStore()
fractionStore.getFractionData()
const offerStore = useOfferStore()
const { currentCity } = storeToRefs(offerStore)
fractionStore.getFractionData()
defineProps<{
  title: string | null
  text: string | null
}>()

const wrongZipcode = ref(true)
const postalCodeString = ref('')
const postalCode = ref('')
const community = ref<number | null>(null)
const cities = ref<CityApiResponse[]>([])
const city = ref('')
const { checkZipcode } = useApiWrapper()
const errorMarker = ref(false)
const emit = defineEmits(['zipCodeEntered'])
const zipDropdown = ref(false)
const checkZipInput = () => {
  cities.value = []
  if (postalCodeString.value.length < 3 || postalCodeString.value.length > 5) {
    return
  }
  checkZipcode(postalCodeString.value).then((response: any) => {
    if (!response || response === null) {
      return
    } else {
      cities.value = response as CityApiResponse[]
      if (cities.value.length < 1) {
        wrongZipcode.value = true
        errorMarker.value = true
      } else {
        errorMarker.value = false
        if (cities.value.length === 1 && postalCode.value !== cities.value[0]?.zip) {
          selectZipCode(cities.value[0])
          zipDropdown.value = false
        } else {
          zipDropdown.value = true
        }
      }
    }
  })
}

const selectZipCode = (response: CityApiResponse) => {
  wrongZipcode.value = false
  errorMarker.value = false
  postalCode.value = response?.zip as string
  city.value = response?.city as string
  community.value = response?.community as number
  postalCodeString.value = `${response?.zip}, ${response?.city}`
}

const setZipcode = () => {
  if (wrongZipcode.value) return
  offerStore.setCurrentCity({
    zip: postalCode.value,
    name: city.value,
    community: community.value as number,
  })
  emit('zipCodeEntered', postalCode.value)
}

const resetPayloadZipcode = () => {
  postalCode.value = ''
  city.value = ''
  community.value = null
  postalCodeString.value = ''
  wrongZipcode.value = true
  errorMarker.value = false
}

onNuxtReady(() => {
  const previousSelectedCity = currentCity.value as City
  if (previousSelectedCity?.zip) {
    cities.value = [
      {
        zip: previousSelectedCity.zip,
        city: previousSelectedCity.name,
        community: previousSelectedCity.community,
      },
    ]
    postalCode.value = previousSelectedCity.zip
    city.value = previousSelectedCity.name
    community.value = previousSelectedCity.community
    postalCodeString.value = `${previousSelectedCity.zip}, ${previousSelectedCity.name}`
    wrongZipcode.value = false
    errorMarker.value = false
  }
})
</script>
<style lang="scss" scoped>
.front-icon {
  z-index: 100;
}
.hero-input :deep(input) {
  text-align: center !important;
}
</style>
